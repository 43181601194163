import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { selectCurrentCountry } from '@stores/router/router.selectors';
import { LangEnum } from '@wizbii/utils/models';

@Component({
  selector: 'app-select-country',
  imports: [MatIcon, NgClass],
  template: `<div class="container">
    <div class="current-country">
      <mat-icon [svgIcon]="country()"></mat-icon>
    </div>

    @if (countrySelectorOpened()) {
      <div class="country-select">
        @for (country of countriesAvailable(); track 'country') {
          <button class="country-select__btn" (click)="selectCountry(country)">
            <mat-icon [svgIcon]="country"></mat-icon>
          </button>
        }
      </div>
    }

    <button
      class="arrow-btn"
      [ngClass]="{ 'arrow-btn--opened': countrySelectorOpened() }"
      (click)="countrySelectorOpened.set(!countrySelectorOpened())"
    >
      <mat-icon svgIcon="angle-right-b"></mat-icon>
    </button>
  </div>`,
  styles: [
    `
      @use 'colors/colors' as colors;

      .container {
        position: absolute;
        left: calc(50% - 30px);
        bottom: 70px;
        background-color: #fff;
        border-radius: 4.25rem;
        padding: 0.25rem;
        color: colors.$wzb-black;
        display: flex;
        align-items: center;
        box-shadow: 0 5px 14px 0 #00000026;
      }

      .current-country {
        display: flex;
        border: 2px solid transparent;
      }

      .arrow-btn {
        display: flex;
        border: none;
        padding: 0;
        transition: transform 0.3s;

        &--opened {
          transform: rotate(180deg);
        }
      }

      .country-select {
        margin-left: 0.25rem;

        &__btn {
          display: flex;
          padding: 0;
          border: 2px solid transparent;
          border-radius: 50%;

          &:hover {
            border: 2px solid #dcd9e3;
            cursor: pointer;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCountryComponent {
  readonly #store = inject(Store);
  readonly country = this.#store.selectSignal(selectCurrentCountry);

  readonly #allCountries = [LangEnum.fr, LangEnum.it];

  countriesAvailable = computed(() => this.#allCountries.filter((country) => country !== this.country()));
  countrySelectorOpened = signal(false);

  selectCountry(country: LangEnum) {
    window.open(`/${country}`, '_self');
  }
}
